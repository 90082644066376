import React from "react";
import Layout from "../components/Layout/Layout";
import WalletAddress from "../components/wallets/WalletAddress";

const Wallets = () => {
  return (
    <>
      <Layout>
        <WalletAddress />
      </Layout>
    </>
  );
};

export default Wallets;
