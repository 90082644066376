import React from "react";
import Layout from "../components/Layout/Layout";
import DepositComponent from "../components/deposit/DepositComponent";

const Deposit = () => {
  return (
    <>
      <Layout>
        <DepositComponent />
      </Layout>
    </>
  );
};

export default Deposit;
