export const test = [
  {
    id: 1,
    person: "Theresa Webb",
    position: "Marketing Coordinator",
    testimony:
      "The Template is really nice and offers quite a large set of options.it's beautiful and the coding is done quickly and seemlessly.thank you",
  },
  {
    id: 2,
    person: "Cameron Willamson",
    position: "Web Designer",
    testimony:
      "The Template is really nice and offers quite a large set of options.it's beautiful and the coding is done quickly and seemlessly.thank you",
  },
  {
    id: 3,
    person: "Marvin McKinney",
    position: "Nursing Assistant",
    testimony:
      "After years of trial and error, few wins and many losses on various platforms. I have finally found the platform that make trading simple and reduces the risk involved.",
  },
  {
    id: 4,
    person: "Becky Lester Lambert",
    position: "Investor",
    testimony:
      "I no longer have to worry about sustaining my trades because with fusionexmarket- all i do is sit back and stakes. Trusted and legit!",
  },
];
